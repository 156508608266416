/* eslint-disable max-len */
// context part
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import useAuth from 'hooks/useAuth';
import hnaRequest from 'utils/hnaRequest';

interface CartProps {
  children: ReactNode
}

export type CartContextType = {
  list: any
  loading: boolean
  boughtList: any
  boughtTotal: any
  totals: any
  handleList: any
  newItemAdded: any
  setNewItemAdded: any
  setAdvantages: any
  advantages: any
}

const initialContext = {
  advantages: [],
  loading: true,
  list: null,
  boughtList: null,
  boughtTotal: null,
  newItemAdded: [],
  totals: {
    standard: 0,
  },
  setAdvantages: () => {},
  handleList: () => {},
  setNewItemAdded: () => {},
};

export const CartContext = createContext<CartContextType>(initialContext);

const CartProvider = ({ children }: CartProps) => {
  const [advantages, setAdvantages] = useState([]);
  const [boughtList, setBoughtList] = useState(null);
  const [boughtTotal, setBoughtTotal] = useState(null);
  const [list, setList] = useState(initialContext.list);
  const [totals, setTotals] = useState(initialContext.totals);
  const [newItemAdded, setNewItemAdded] = useState(false);
  const [loading, setLoading] = useState(initialContext.loading);
  const { user } = useAuth();

  const handleList = (addedList) => {
    setList(addedList);
  };

  useEffect(() => {
    const setCartList = async () => {
      const res = await hnaRequest('get', 'purchase/cart-list', undefined);
      setLoading(false);
      setList(res?.cart);
    };
    if (user) setCartList();
  }, [user]);

  useEffect(() => {
    const newTotals = { standard: list?.items.reduce((acc, value) => acc + (Number(value?.price?.standard) || 0), 0) };
    setTotals(newTotals);
    if (list) {
      setBoughtList(list);
      setBoughtTotal(newTotals);
    }
  }, [list]);

  return (
    <CartContext.Provider value={{
      list,
      handleList,
      newItemAdded,
      setNewItemAdded,
      totals,
      advantages,
      setAdvantages,
      boughtList,
      boughtTotal,
      loading,
    }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
