import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import useHistory from 'hooks/useHistory';
import hnaRequest from 'utils/hnaRequest';

interface ThemesFiltersProps {
  children: ReactNode
}

export type ThemesFiltersContextType = {
  filters: any
  handleFilters: any
  themes: any
  contentCount: any
  trainingCount: any
}

const defaultTheme = { id: 0, name: 'Toutes les thématiques' };

const initialContext = {
  filters: { informations: [defaultTheme], formations: [defaultTheme], 'mon-academy': [defaultTheme] },
  themes: [],
  contentCount: 0,
  trainingCount: 0,
  handleFilters: () => {},
};

export const ThemesFiltersContext = createContext<ThemesFiltersContextType>(initialContext);

const ThemesFiltersProvider = ({ children }: ThemesFiltersProps) => {
  const [filters, setFilters] = useState(initialContext.filters);
  const [themes, setThemes] = useState([]);
  const { home, query } = useHistory();

  const contentCount = themes.reduce((acc, value) => acc + value.contentCount || 0, 0);
  const trainingCount = themes.reduce((acc, value) => acc + value.trainingCount || 0, 0);

  useEffect(() => {
    const getContentThemes = async () => {
      const ret = await hnaRequest('get', 'content/themes', undefined);
      setThemes([defaultTheme, ...ret.themes.map((el) => {
        if (el.id === 19) return { ...el, displayName: 'Troubles neurocog./acquis' };
        if (el.id === 20) return { ...el, displayName: 'Troubles neurodév.' };
        return el;
      })]);
    };
    getContentThemes();
  }, []);
  // console.log({ themes });
  const handleFilters = (newTheme) => {
    let newFilters = initialContext.filters[home];
    const elem = filters[home]?.find((el) => el.id === newTheme.id);
    if (newTheme.id === 0) newFilters = [defaultTheme];
    else if (elem) {
      let newList = filters[home]?.filter((el) => el.id !== newTheme.id);
      if (!newList.length) newList = [defaultTheme];
      newFilters = newList;
    } else {
      newFilters = [...filters[home].filter((e) => e.id !== 0), newTheme];
    }
    localStorage.setItem(home, JSON.stringify(newFilters));
    setFilters({ ...filters, [home]: newFilters });
    return newFilters;
  };

  useEffect(() => {
    const savingFilters = localStorage.getItem(home);
    const urlThemesId = query.themes?.split(',');
    const arrayFilter = [];

    if (urlThemesId?.length) {
      urlThemesId?.forEach((element) => {
        arrayFilter.push({ id: Number(element) });
      });
      // console.log({ arrayFilter });
      setFilters((pFilters) => ({ ...pFilters, [home]: arrayFilter }));
    } else if (savingFilters) {
      setFilters((pFilters) => ({ ...pFilters, [home]: JSON.parse(savingFilters) }));
    }
  }, [home]);

  return (
    <ThemesFiltersContext.Provider value={{
      filters: filters[home], handleFilters, themes, contentCount, trainingCount,
    }}
    >
      {children}
    </ThemesFiltersContext.Provider>
  );
};

export default ThemesFiltersProvider;
