import styled, { css } from 'styled-components';

const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 45%);
  top: 0;
  display: flex;
  z-index: 100;
`;

const ModalContent = styled.div<any>`
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => (width || '52.3vw')};
  /* min-height: ${({ small }) => (small ? '25vh' : '67.2vh')}; */
  max-height: ${({ small }) => (small ? '25vh' : '82vh')};
  height: min-content;
  overflow: auto;
  margin: auto;
  z-index: 10;
  ${({ small }) => small && css`
    background: #ffffffcf;
    box-shadow: 0px 0px 115px #00000038;
    border-radius: 10px;
  `}
`;

const close = css`
  position: relative;
  width: 2vw;
  margin-bottom: -2.5vw;
  margin-left: auto;
  top: 1vw;
  right: 1vw;
  cursor: pointer;
`;

export default {
  Modal,
  ModalContent,
  close,
};
