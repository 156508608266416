import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { notification } from 'antd';
import useAuth from 'hooks/useAuth';
import hnaRequest from 'utils/hnaRequest';
import { isUserTrial } from 'utils/auth';

interface TrialProps {
  children: ReactNode
}

export type TrialContextType = {
  trialStatus: string
  trialLoading: boolean
  displayTrial: boolean
  modalTrial: boolean
  daysLeft: number
  handleTrial: (bool: boolean) => void
  setModalTrial: (bool: boolean) => void
}

const status = {
  START: 'START',
  CURRENT: 'CURRENT',
  END: 'END',
  DISABLE: 'DISABLE',
};

const initialContext = {
  trialStatus: status.DISABLE,
  displayTrial: false,
  trialLoading: false,
  modalTrial: false,
  daysLeft: 0,
  handleTrial: () => {},
  setModalTrial: () => {},
};

export const TrialContext = createContext<TrialContextType>(initialContext);

const TrialProvider = ({ children }: TrialProps) => {
  const [modalTrial, setModalTrial] = useState(initialContext.modalTrial);
  const [trialStatus, setStatus] = useState(status.DISABLE);
  const [trialLoading, setTrialLoading] = useState(false);

  const { refetchProfile, user } = useAuth();
  const isSubscribed = user?.subscription?.active && user?.subscription?.type === 'subscriber';
  const { trial, daysLeft } = isUserTrial(user);

  useEffect(() => {
    if (isSubscribed) return setStatus(status.DISABLE);
    if (!user?.trial?.trialStatus) return setStatus(status.START);
    if (trial) return setStatus(status.CURRENT);
    if (user?.trial?.trialStatus && daysLeft < 0) {
      setStatus(status.END);
      const trialEnded = localStorage.getItem('trialEnded');
      if (trialEnded === 'true' || Math.ceil(daysLeft) <= -7) return undefined;
      localStorage.setItem('trialEnded', 'true');
      setModalTrial(true);
    }
    return undefined;
  }, [user]);

  const handleTrial = async (hasAccepted) => {
    try {
      if (hasAccepted) {
        setTrialLoading(true);
        await hnaRequest('get', 'purchase/activate-trial-period', undefined);
        setStatus('CURRENT');
        refetchProfile();
        notification.success({
          message: "Votre période d'essai a bien été pris en compte.",
        });
      }
      setTrialLoading(false);
      setModalTrial(false);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Veuillez nous excuser, une erreur est survenue.',
      });
      setTrialLoading(false);
      setModalTrial(false);
    }
  };

  return (
    <TrialContext.Provider value={{
      trialStatus, handleTrial, modalTrial, daysLeft, setModalTrial, displayTrial: ['START', 'CURRENT'].includes(trialStatus), trialLoading,
    }}
    >
      {children}
    </TrialContext.Provider>
  );
};

export default TrialProvider;
