import styled, { css } from 'styled-components';

const Carousel = styled.div`
  position: relative;
`;

const ArrowLeft = styled.div`
  ${Carousel}:hover & {
    display: flex;
  }

  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(26, 26, 26, 0.83) 0% 0% no-repeat padding-box;
  z-index: 60;
  height: 13.5185vh;
  width: 13.5185vh;
  left: calc(13.5185vh / -2);
  transform: matrix(-1, 0, 0, -1, 0, 0);
  border-radius: 50%;
  cursor: pointer;
  margin-top: calc(0.7407407407407407vh + 0.648vh);
`;

const ArrowRight = styled.div`
  position: relative;
  ${Carousel}:hover & {
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(26, 26, 26, 0.83) 0% 0% no-repeat padding-box;
  z-index: 60;
  height: 13.5185vh;
  width: 13.5185vh;
  right: calc(13.5185vh / -2);
  border-radius: 50%;
  cursor: pointer;
  margin-top: calc(0.7407407407407407vh + 0.648vh);
  opacity: 0;
  transition: opacity 500ms;
`;

const arrow = css`
  display: flex;
  width: 4.7vw;
  height: 4.7vw;
  margin-left: -3vw;
`;

const flexEnd = css`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 3.611111vh;
`;

const Dot = styled.div<any>`
  cursor: pointer;
  width: 1.3021vw;
  height: 0.2777vh;
  background-color: ${({ selected }) => (selected ? 'var(--color-black-02)' : '#DCDCDC')};
  margin-left: 0.3125vw;
`;

const fading = css`
  position: absolute;
  background: transparent linear-gradient(270deg,#F5F5F5 0%,#F5F5F500 100%) 0% 0% no-repeat padding-box;
  width: 6vw;
  height: calc(100% - var(--scrollbar-width));
  right: -0.5vw;
  top: 0;
  z-index: 4;
  pointer-events: none;
`;

export default {
  Carousel,
  ArrowLeft,
  ArrowRight,
  arrow,
  flexEnd,
  Dot,
  fading,
};
