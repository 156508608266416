import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import SC from './style';
import { text1 } from 'components/Texts/style';
import { DivFlex, pointer } from 'styles/common';
import Carousel from 'components/Carousel';
import { duration } from 'utils/animations';
import CardWrapper from '../CardWrapper';
import { IContentItem } from '../../domain/content';
import useDatas from 'hooks/useDatas';
import getProgramVendor from 'utils/getProgramVendor';
import useTrial from 'hooks/useTrial';
import { SvgAllProgram } from 'components/Svgs';

export interface CatalogueProps {
  title?: string
  name?: string
  list?: IContentItem[];
  setOpenDetail?: any
  isExtended?: boolean
  unClickable?: boolean
  onClick?: () => void
  user: any
  category?: any
  autoAdjust?: any
  currentPodcast?: any
}

const titleLabel = {
  ARTICLE: 'Articles de fond',
  LITERATURE: 'Littérature scientifique',
  MEMOIR: 'Mémoires primés',
  POST: 'Billets',
  INTERVIEW: 'Interviews',
  DEBOECK: 'Contenus exclusifs DeBoeck',
};

const Catalogue: FC<CatalogueProps> = ({
  title, list, isExtended, onClick, unClickable, name, user, autoAdjust, currentPodcast,
}) => {
  const { baughtTraining } = useDatas();
  const { displayTrial } = useTrial();

  if (!list) return null;

  return (
    <SC.SectionCatalogue isExtended={isExtended} unClickable={unClickable}>
      <DivFlex align="baseline">
        <h1 css={[text1, pointer]} onClick={onClick} role="presentation">{titleLabel[title] || title}</h1>
        {!unClickable && !isExtended && <SC.SpanExploreAll onClick={onClick} role="presentation">Tout explorer &gt;</SC.SpanExploreAll>}
        {name === 'listProgrammeMonth' && (
          <DivFlex cursor="pointer" margin="0 0 auto auto" align="center">
            <button
              type="button"
              onClick={() => window.open('https://accueil.happyneuron.academy/au-programme-ce-mois-ci/')}
              css={SC.allProgram}
            >
              Voir le programme du mois
            </button>
            <div css={SC.imgProgram}>
              <SvgAllProgram />
            </div>
          </DivFlex>
        )}
      </DivFlex>

      <Carousel
        totalElements={list.length}
        targetId={name}
        disabled={unClickable || isExtended}
      >
        <SC.UlList className="scrollbar" headbandheight={!user ? '76vh' : user && displayTrial ? '71vh' : '78vh'} component="ul" id={name} isextended={`${isExtended}`} user={user}>
          {list?.map((card) => {
            const programVendor = getProgramVendor(card, baughtTraining);
            return (
              <CSSTransition
                key={card.id}
                timeout={duration}
                classNames="item"
              >
                <li>
                  <CardWrapper
                    {...card}
                    {...programVendor}
                    user={user}
                    category={card.contentType ? 'content' : 'training'}
                    autoAdjust={autoAdjust}
                    currentPodcast={currentPodcast}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </SC.UlList>
      </Carousel>
    </SC.SectionCatalogue>
  );
};

export default Catalogue;
