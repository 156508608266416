import { isUserSubscribed } from './auth';
import roundDecimal from './roundDecimal';

const getGoodPaymentPrice = ({
  price, user, isOfferAvailable, advantage = null,
}) => {
  const isSubscribed = isUserSubscribed(user);
  if (isSubscribed) {
    return price.standard ? roundDecimal(Number(price.standard) * (isOfferAvailable ? 0.8 : 1) - (advantage?.reduction || 0))
      : 0;
  }
  return price.standard ? roundDecimal(Number(price.standard) * (isOfferAvailable ? 0.8 : 1) - (advantage?.reduction || 0)) : 0;
};

export default getGoodPaymentPrice;
