import { ReactElement } from 'react';
import { SvgClose } from 'components/Svgs';
import SC from './style';

interface ModalProps {
  onClose?: any
  small?: boolean
  height?: string
  width?: string
  children: ReactElement[] | ReactElement
}

const Modal: React.FC<ModalProps> = ({
  children, onClose, small, height, width,
}: ModalProps) => (
  <SC.Modal>
    <SC.ModalContent width={width} height={height} small={small}>
      {onClose && (
        <div
          onClick={onClose}
          onKeyPress={onClose}
          role="button"
          tabIndex={0}
          css={SC.close}
        >
          <SvgClose />
        </div>
      )}
      {children}
    </SC.ModalContent>
  </SC.Modal>
);

export default Modal;
