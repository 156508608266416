import { TransitionGroup } from 'react-transition-group';
import styled, { css } from 'styled-components';

const SectionCatalogue = styled.section<any>`
  overflow:  ${({ unClickable, theme: { mobile } }) => (unClickable && !mobile ? '100%' : 'hidden')};
  position: relative;
  margin-bottom: ${({ isExtended }) => !isExtended && 'var(--height-xs)'};
  z-index: 1;
  height: ${({ isExtended }) => isExtended && '100%'};
`;

const SpanExploreAll = styled.span<any>`
  opacity: 0;
  transition-duration: 0.2s;
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-18);
  color: #1A1A1A;
  margin-left: -2vh;
  cursor: pointer;

  ${SectionCatalogue}:hover & {
    opacity: 1;
    margin-left: 1.2vh;
  }
`;

const UlList = styled(TransitionGroup)<any>`
  display: flex;
  user-select: none;
  scroll-behavior: smooth;
  margin: ${({ isextended }) => (isextended === 'true' ? 'var(--height-xs) 0 0 0' : '0')};
  overflow-x: ${({ isextended }) => (isextended === 'true' ? 'hidden' : 'auto')};
  overflow-y: ${({ isextended }) => (isextended === 'true' ? 'auto' : 'hidden')};
  max-height: ${({ isextended }) => (isextended === 'true' ? '69vh' : 'auto')};
  max-height: ${({ isextended, user, headbandheight }) => (isextended === 'true' && user && headbandheight)};
  margin-top: 0.2vh;

  ${({ isextended }) => isextended === 'true' && css`
    flex-wrap: wrap;

    & > * {
      margin-bottom: var(--list-item-margin);
    }
  `}

  & > * {
    padding-right: var(--list-item-margin);
  }

  & > :last-child {
    padding-right: 0;
  }


  transition: all 500ms ease-out;
`;

const allProgram = css`
  margin-left: auto;
  font-weight: bold;
  margin-right: 0.5vw;
  padding: 0;
  background: 0;
  outline: none;
  border: none;
`;

const imgProgram = css`
  width: 1.5vw;
  height: 1.5vw;
`;

export default {
  SectionCatalogue,
  UlList,
  SpanExploreAll,
  allProgram,
  imgProgram,
};
