/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { SvgArrowRight } from 'components/Svgs';
import SC from './style';
import { duration } from 'utils/animations';
import useThemesFilters from 'hooks/useThemesFilters';
import useHistory from 'hooks/useHistory';

export interface CarouselProps {
  disabled?: boolean
  active?: boolean
  targetId?: string
  totalElements?: number
  children: any
}

const Carousel: React.FC<CarouselProps> = ({
  children, disabled, targetId, totalElements,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(null);
  const { filters } = useThemesFilters();
  const nodeRef = useRef(null);
  const { home } = useHistory();

  useEffect(() => {
    const elem = document.getElementById(targetId) as any;
    const handleCurrentStep = (array) => {
      if (elem.scrollLeft + elem.clientWidth + 1 >= elem.scrollWidth) {
        setCurrentStep(array.length - 1);
        return;
      }
      for (let index = 0; index < array.length; index += 1) {
        if (elem.scrollLeft < array[index]) {
          setCurrentStep(index - 1);
          break;
        }
      }
    };
    if (!steps) return () => elem.removeEventListener('scroll', handleCurrentStep);
    elem.scrollLeft = 0;

    elem.addEventListener('scroll', () => handleCurrentStep(steps));

    return () => elem.removeEventListener('scroll', handleCurrentStep);
  }, [steps, targetId, filters?.length, home]);

  useEffect(() => {
    setTimeout(() => {
      if (disabled) return;
      const elem = document.getElementById(targetId) as any;
      if (!elem?.children[0]) return;
      const nbrElemsVisibled = Math.round(elem.clientWidth / elem.children[0].offsetWidth) - 1;
      const numberOfStep = Math.ceil(elem.scrollWidth
      / ((elem.scrollWidth / elem.children.length) * nbrElemsVisibled));
      if (numberOfStep === 1) {
        setSteps(null);
        return;
      }
      const listSteps = [];
      for (let index = 0; index < numberOfStep; index += 1) {
        listSteps[index] = Math.trunc((elem.scrollWidth / elem.childNodes.length) * (nbrElemsVisibled * index));
      }
      setSteps(listSteps);

      if (elem.scrollLeft + elem.clientWidth >= elem.scrollWidth) {
        setCurrentStep(listSteps.length - 1);
        return;
      }
      for (let index = 0; index < listSteps.length; index += 1) {
        if (elem.scrollLeft < listSteps[index]) {
          setCurrentStep(index - 1);
          break;
        }
      }
    }, duration + 400);
  }, [filters?.length, home, disabled, targetId, totalElements]);

  const handleScroll = (newStep) => {
    if (newStep < 0) return;
    const elem = document.getElementById(targetId);
    elem.scrollLeft = steps[newStep];
  };

  return (
    <>
      <CSSTransition
        in={!!(!disabled && steps?.length)}
        timeout={500}
        classNames="carousel"
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div css={SC.flexEnd}>
          {steps?.map((endPoint, i) => (
            <SC.Dot key={endPoint} onClick={() => handleScroll(i)} selected={i === currentStep} />
          ))}
        </div>
      </CSSTransition>
      <SC.Carousel>

        {!!(!disabled && steps?.length && currentStep !== 0) && (
        <SC.ArrowLeft onClick={() => handleScroll(currentStep - 1)}>
          <div css={SC.arrow}>
            <SvgArrowRight />
          </div>
        </SC.ArrowLeft>
        )}

        {!!(!disabled && steps?.length) && (
        <SC.ArrowRight onClick={() => handleScroll(currentStep + 1)}>
          <div css={SC.arrow}>
            <SvgArrowRight />
          </div>
        </SC.ArrowRight>
        )}

        {children}
        <CSSTransition
          in={!!(!disabled && steps?.length)}
          timeout={500}
          classNames="carousel"
          unmountOnExit
          nodeRef={nodeRef}
        >
          <div role="presentation" onClick={() => handleScroll(currentStep + 1)} css={SC.fading} />
        </CSSTransition>
      </SC.Carousel>
    </>
  );
};

export default Carousel;
