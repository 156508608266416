import { isUserSubscribed } from './auth';
import roundDecimal from './roundDecimal';

const getGoodFormattedPrice = ({
  id, price, advantages, user, isOfferAvailable,
}) => {
  const isSubscribed = isUserSubscribed(user);
  if (isSubscribed) {
    const advantage = advantages?.find((a) => a.id === id);
    if ((price?.standard - advantage?.reduction === 0)) return 'OFFERTE';
    return `${roundDecimal(Number(price?.standard - (advantage?.reduction || 0)) * (isOfferAvailable ? 0.8 : 1))}€`;
  }
  const newPrice = isOfferAvailable ? Number(price.standard) * 0.8 : price.standard;
  return price.standard
    ? `${roundDecimal(newPrice)}€` : 'OFFERTE';
};

export default getGoodFormattedPrice;
